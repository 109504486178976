/*Rotis Semi Serif*/
@font-face {
    font-family: 'Rotis Semi Serif Std';
    src: url('../fonts/rotisSemiSerif/RotisSemiSerifStd.woff2') format('woff2'),
        url('../fonts/rotisSemiSerif/RotisSemiSerifStd.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/*Rotis Semi Serif Bold*/
@font-face {
    font-family: 'Rotis Semi Serif Std Bold';
    src: url('../fonts/rotisSemiSerifBold/RotisSemiSerifStd-Bold.eot');
    src: url('../fonts/rotisSemiSerifBold/RotisSemiSerifStd-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/rotisSemiSerifBold/RotisSemiSerifStd-Bold.woff2') format('woff2'),
        url('../fonts/rotisSemiSerifBold/RotisSemiSerifStd-Bold.woff') format('woff'),
        url('../fonts/rotisSemiSerifBold/RotisSemiSerifStd-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/*Rotis Sand Serif*/
@font-face {
    font-family: 'Rotis SansSerif Std';
    src: url('../fonts/rotisSandSerif/RotisSansSerifStd.eot');
    src: url('../fonts/rotisSandSerif/RotisSansSerifStd.eot?#iefix') format('embedded-opentype'),
        url('../fonts/rotisSandSerif/RotisSansSerifStd.woff2') format('woff2'),
        url('../fonts/rotisSandSerif/RotisSansSerifStd.woff') format('woff'),
        url('../fonts/rotisSandSerif/RotisSansSerifStd.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/*Antro Vectra*/
@font-face {
    font-family: 'Antro Vectra';
    src: url('../fonts/antroVectra/AntroVectra.eot');
    src: url('../fonts/antroVectra/AntroVectra.eot?#iefix') format('embedded-opentype'),
        url('../fonts/antroVectra/AntroVectra.woff2') format('woff2'),
        url('../fonts/antroVectra/AntroVectra.woff') format('woff'),
        url('../fonts/antroVectra/AntroVectra.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

